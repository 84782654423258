import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { LoadingPage } from "routes";

import { LoginPage } from "./auth/login";
import { ANY_OTHER, AUTH } from "./consts";

export const PublicRoutes = () => (
  <Suspense fallback={<LoadingPage />}>
    <Routes>
      <Route path={AUTH}>
        <Route index element={<LoginPage />} />
      </Route>
      <Route path={ANY_OTHER} element={<Navigate to={AUTH} replace />} />
    </Routes>
  </Suspense>
);
