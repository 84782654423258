import {
  Box,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { useState } from "react";

import { useLogout } from "api";

export const NavbarDrawer = () => {
  const [open, setOpen] = useState(false);
  const logoutMutation = useLogout();

  const toggleDrawer = (open: boolean) => () => {
    setOpen(open);
  };

  const handleLogout = async (event: React.MouseEvent) => {
    event.preventDefault();
    await logoutMutation.mutateAsync();
  };
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <Icon>logout</Icon>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton
        onClick={toggleDrawer(true)}
        size="small"
        color="secondary"
        aria-label="Settings"
        sx={{
          bgcolor: "#1c1c1e",
          borderRadius: "100%",
          padding: 1,
          ":hover": {
            bgcolor: "#18181a",
          },
        }}
      >
        <Icon>more_horiz</Icon>
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
};
