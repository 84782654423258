import { ChartDateType, EnergyType } from "api";
import { createSafeContext } from "utils/createSafeContext";

type RecordsContextType = {
  date: Record<ChartDateType, Date>;
  setDate: React.Dispatch<React.SetStateAction<Record<ChartDateType, Date>>>;
  dateType: ChartDateType;
  setDateType: React.Dispatch<React.SetStateAction<ChartDateType>>;
  energyType: EnergyType;
  setEnergyType: React.Dispatch<React.SetStateAction<EnergyType>>;
};

export const [RecordsContext, useRecordsContext] =
  createSafeContext<RecordsContextType>("RecordsContext");
