import { Box, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

export const Puller = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
        ...theme.applyStyles("dark", {
          backgroundColor: grey[700],
        }),
      }}
    />
  );
};
