import { useState } from "react";

import { ChartDateType, EnergyType } from "api";

import { RecordsContext } from "./RecordsContext";

export const RecordsContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [date, setDate] = useState<Record<ChartDateType, Date>>({
    day: new Date(),
    month: new Date(),
    year: new Date(),
    total: new Date(),
  });
  const [dateType, setDateType] = useState<ChartDateType>("day");
  const [energyType, setEnergyType] = useState<EnergyType>("energyProduced");

  return (
    <RecordsContext.Provider
      value={{
        date,
        setDate,
        dateType,
        setDateType,
        energyType,
        setEnergyType,
      }}
    >
      {children}
    </RecordsContext.Provider>
  );
};
