import { Grid2 } from "@mui/material";

import {
  AdditionalReadings,
  CurrentPower,
  EnergyChart,
  EnergyInfoToday,
} from "components/elements/widgets";
import { RECORDS } from "routes/consts";
import { getLiveData, useAppSelector } from "store";

export const DashboardPage = () => {
  const liveData = useAppSelector(getLiveData);

  if (!liveData) return null;

  return (
    <Grid2 container spacing={2} p={2}>
      <CurrentPower />
      <EnergyInfoToday
        energyType="energyProduced"
        icon="solar_power"
        url={RECORDS}
      />

      <EnergyChart url={RECORDS} />
      <AdditionalReadings />
    </Grid2>
  );
};
