import { Chip } from "@mui/material";

export const DatePickerChip = ({
  label,
  onClick,
  variant,
}: {
  label?: string;
  onClick?: () => void;
  variant?: "outlined" | "filled";
}) => {
  return <Chip variant={variant} label={label} onClick={onClick} />;
};
