import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSetupAxios } from "api";
import { LoadingPage } from "routes";
import { getTokens, getUser, useAppSelector } from "store";

import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { useSetupApp } from "./utils";

export const Routes: React.FC = () => {
  const navigate = useNavigate();
  const tokens = useAppSelector(getTokens);
  const user = useAppSelector(getUser);

  useSetupAxios();
  useSetupApp();

  const [newVersion, setNewVersion] = useState(false);

  useEffect(() => {
    const handleNewVersion = (event: Event) => {
      event.preventDefault();
      setNewVersion(true);
      navigate(0);
    };
    window.addEventListener("vite:preloadError", handleNewVersion);
    return () => {
      window.removeEventListener("vite:preloadError", handleNewVersion);
    };
  }, [navigate]);

  if ((tokens && !user) || newVersion) {
    return <LoadingPage />;
  }

  if (!user) {
    return <PublicRoutes />;
  }

  return <PrivateRoutes />;
};
