import { EnergyType } from "api";
import { Widget, WidgetContent } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { getLiveData, useAppSelector } from "store";
import { formatEnergy } from "utils/energy";

export const EnergyInfoToday = ({
  energyType,
  icon,
  url,
}: {
  energyType: EnergyType;
  icon?: string;
  url?: string;
}) => {
  const formatMessage = useFormatMessage();
  const liveData = useAppSelector(getLiveData);

  if (!liveData) return null;

  const energy = formatEnergy(liveData[energyType].day);

  return (
    <Widget
      title={`${formatMessage(energyType)} (${formatMessage("today")})`}
      icon={icon}
      content={<WidgetContent value={energy.value} unit={energy.unit} />}
      url={url}
    />
  );
};
