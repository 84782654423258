import { useTheme } from "@mui/material";
import { useMemo } from "react";
import {
  Area,
  Bar,
  ComposedChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ChartDateType, EnergyType, useGetRecords } from "api";
import { Widget } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { getSelectedPowerPlant, useAppSelector } from "store";

import { EnergyChartTooltip } from "./components";
import { createHelperFunctionsGrouping } from "./utils";

export const EnergyChart = ({
  title,
  url,
  date = {
    day: new Date(),
    month: new Date(),
    year: new Date(),
    total: new Date(),
  },
  dateType = "day",
  energyType = "energyProduced",
}: {
  title?: string;
  url?: string;
  date?: Record<ChartDateType, Date>;
  dateType?: ChartDateType;
  energyType?: EnergyType;
}) => {
  const theme = useTheme();
  const formatMessage = useFormatMessage();
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);

  const helperFunctions = useMemo(
    () => createHelperFunctionsGrouping(selectedPowerPlant),
    [selectedPowerPlant],
  );

  const startDate = useMemo(
    () => helperFunctions[dateType].start(date[dateType]),
    [helperFunctions, date, dateType],
  );
  const endDate = useMemo(
    () => helperFunctions[dateType].end(date[dateType]),
    [helperFunctions, date, dateType],
  );
  const { data } = useGetRecords({
    request: {
      startDate: startDate!.toISOString(),
      endDate: endDate!.toISOString(),
    },
    options: {
      refetchInterval: selectedPowerPlant?.recordSaveInterval,
      refetchOnWindowFocus: true,
      staleTime: selectedPowerPlant?.recordSaveInterval,
      enabled: !!(startDate && endDate),
    },
  });

  const preparedData = useMemo(
    () =>
      data
        ? helperFunctions[dateType].prepareData(data, date[dateType])
        : undefined,
    [data, helperFunctions, date, dateType],
  );
  const noData = useMemo(() => !data || data.length === 0, [data]);

  return (
    <Widget
      title={title ?? formatMessage(`chart.${energyType}`)}
      icon={dateType === "day" ? "show_chart" : "bar_chart"}
      url={url}
      onlyHeaderClickable
      size={{ xs: 12 }}
      content={
        <ResponsiveContainer height={300}>
          <ComposedChart
            data={preparedData}
            margin={{ top: 20, right: 10, bottom: 0, left: noData ? -50 : 0 }}
          >
            {noData ? (
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                fill={theme.palette.text.disabled}
              >
                {formatMessage("chart.noData")}
              </text>
            ) : (
              <>
                <XAxis
                  dataKey="date"
                  scale={helperFunctions[dateType].chartOptions.scaleType}
                  ticks={helperFunctions[dateType].generateTicks(
                    date[dateType],
                  )}
                  tickFormatter={(value) =>
                    helperFunctions[dateType].formatXAxis(value)
                  }
                  min={startDate.getTime()}
                  max={endDate.getTime()}
                  tick={{
                    fill: theme.palette.text.primary,
                    fontSize: 12,
                    fontFamily: theme.typography.fontFamily,
                  }}
                  axisLine={{
                    stroke: theme.palette.divider,
                    strokeWidth: 1,
                  }}
                />
                <YAxis
                  dataKey={energyType}
                  tickFormatter={(value) =>
                    helperFunctions[dateType].formatYAxis(value)
                  }
                  tick={{
                    fill: theme.palette.text.primary,
                    fontSize: 12,
                    fontFamily: theme.typography.fontFamily,
                  }}
                  axisLine={{
                    stroke: theme.palette.divider,
                    strokeWidth: 1,
                  }}
                />
                <Tooltip
                  content={
                    <EnergyChartTooltip
                      formatLabel={helperFunctions[dateType].formatXTooltip}
                      formatValue={helperFunctions[dateType].formatYTooltip}
                      isPower={dateType === "day"}
                    />
                  }
                  cursor={{
                    strokeDasharray: "5 5",
                    stroke: theme.palette.divider,
                    strokeWidth: 2,
                  }}
                />
                <Bar
                  dataKey={energyType}
                  fill={theme.palette.secondary.main}
                  activeBar={<Rectangle fill="#413ea0" />}
                  hide={
                    helperFunctions[dateType].chartOptions.seriesType !== "bar"
                  }
                />
                <Area
                  type="monotone"
                  dataKey={energyType}
                  stroke={theme.palette.secondary.main}
                  strokeWidth={2}
                  fill="url(#colorGradient)"
                  hide={
                    helperFunctions[dateType].chartOptions.seriesType !== "line"
                  }
                />
              </>
            )}
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.secondary.main}
                  stopOpacity={0.9}
                />
                <stop
                  offset="70%"
                  stopColor={theme.palette.secondary.main}
                  stopOpacity={0.3}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.secondary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
          </ComposedChart>
        </ResponsiveContainer>
      }
    />
  );
};
