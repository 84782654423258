import { Chip, Grid2 } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useState } from "react";

import { chartDate, energyTypeList, HelperFunctionsDatePicker } from "api";
import { Widget } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { getSelectedPowerPlant, useAppSelector } from "store";

import { DatePickerChip } from "./components";
import { useRecordsContext } from "../context";

export const RecordsOptionsPicker = () => {
  const formatMessage = useFormatMessage();
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const { date, setDate, dateType, setDateType, energyType, setEnergyType } =
    useRecordsContext();

  const powerPlantStartDate = selectedPowerPlant?.startDate
    ? new Date(selectedPowerPlant.startDate)
    : undefined;
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  const helperFunctions: HelperFunctionsDatePicker = {
    day: {
      formatDateType: (date: Date) => format(date, "P"),
      datePickerOptions: {
        views: ["year", "month", "day"],
        hidden: false,
      },
    },
    month: {
      formatDateType: (date: Date) => format(date, "M.y"),
      datePickerOptions: {
        views: ["year", "month"],
        hidden: false,
      },
    },
    year: {
      formatDateType: (date: Date) => format(date, "y"),
      datePickerOptions: {
        views: ["year"],
        hidden: false,
      },
    },
    total: {
      formatDateType: undefined,
      datePickerOptions: {
        views: [],
        hidden: true,
      },
    },
  };

  return (
    <Widget
      title={formatMessage("filters")}
      size={{ xs: 12 }}
      collapsible
      expandedByDefault={false}
      content={
        <Grid2 container spacing={2}>
          <Grid2
            container
            spacing={1}
            sx={{
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              overflowX: "auto",
              scrollbarGutter: "stable",
            }}
          >
            {chartDate.map((item) => (
              <DatePicker
                key={item}
                views={helperFunctions[dateType].datePickerOptions.views}
                value={date[dateType]}
                minDate={powerPlantStartDate}
                maxDate={new Date()}
                open={datePickerOpen}
                onClose={() => setDatePickerOpen(false)}
                onOpen={() => setDatePickerOpen(true)}
                onChange={(newValue: Date | null) =>
                  setDate((values) => ({
                    ...values,
                    [dateType]: newValue,
                  }))
                }
                slots={{
                  field: DatePickerChip,
                }}
                slotProps={{
                  field: {
                    zIndex: 1,
                    label:
                      dateType === item &&
                      helperFunctions[dateType].formatDateType
                        ? helperFunctions[dateType].formatDateType(
                            date[dateType],
                          )
                        : formatMessage(`grouping.${item}`),
                    onClick: () => {
                      setDateType(item);
                      if (dateType === item && item !== "total")
                        setDatePickerOpen(true);
                    },
                    variant: dateType === item ? "filled" : "outlined",
                  } as {
                    label: string;
                    onClick: () => void;
                    variant: "outlined" | "filled";
                  },
                }}
              />
            ))}
          </Grid2>
          <Grid2
            container
            spacing={1}
            sx={{
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              overflowX: "auto",
              scrollbarGutter: "stable",
            }}
          >
            {energyTypeList.map((item) => (
              <Chip
                key={item}
                variant={energyType === item ? "filled" : "outlined"}
                label={formatMessage(item)}
                onClick={() => setEnergyType(item)}
              />
            ))}
          </Grid2>
        </Grid2>
      }
    />
  );
};
