import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { axiosInstance, LiveDataResponse, UserResponse } from "api";
import { TOKENS_LOCAL_STORAGE_KEY } from "store/consts";
import { setToLocalStorage } from "utils/localStorage";

import { AppState } from "./types";

const initialState: AppState = {
  powerPlants: [],
  selectedPowerPlant: undefined,
  showLiveData: false,
  user: undefined,
  tokens: undefined,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLiveData(state, action: PayloadAction<LiveDataResponse>) {
      const powerPlant = state.powerPlants.find(
        (powerPlant) => powerPlant.meterId === action.payload.meterId,
      );

      if (powerPlant) powerPlant.liveData = action.payload.liveData;
      if (state.selectedPowerPlant?.meterId === action.payload.meterId)
        state.selectedPowerPlant.liveData = action.payload.liveData;
    },
    setShowLiveData(state, action: PayloadAction<boolean>) {
      state.showLiveData = action.payload;
    },
    setSelectedPowerPlant(
      state,
      action: PayloadAction<AppState["selectedPowerPlant"]>,
    ) {
      state.selectedPowerPlant = action.payload;
    },
    setUser(state, action: PayloadAction<AppState["user"]>) {
      state.user = action.payload;
    },
    setTokens(state, action: PayloadAction<AppState["tokens"]>) {
      state.tokens = action.payload;
      setToLocalStorage(TOKENS_LOCAL_STORAGE_KEY, action.payload);
      axiosInstance.defaults.headers["Authorization"] =
        `Bearer ${action.payload?.accessToken}`;
    },
    handleUserResponse(state, action: PayloadAction<UserResponse>) {
      state.user = action.payload.user;
      state.powerPlants = action.payload.powerPlants;
      state.selectedPowerPlant = state.powerPlants[0];
    },
  },
});

export const {
  setLiveData,
  setShowLiveData,
  setSelectedPowerPlant,
  setUser,
  setTokens,
  handleUserResponse,
} = appSlice.actions;
export default appSlice.reducer;
