import { Grid2 } from "@mui/material";

import { EnergyType } from "api";
import { Widget, WidgetContent } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { getLiveData, useAppSelector } from "store";
import { formatEnergy } from "utils/energy";

export const EnergyInfoSummary = ({
  energyType,
  title,
  icon,
  url,
}: {
  energyType: EnergyType;
  title?: string;
  icon?: string;
  url?: string;
}) => {
  const formatMessage = useFormatMessage();
  const liveData = useAppSelector(getLiveData);

  if (!liveData) return null;

  const energyDay = formatEnergy(liveData[energyType].day);
  const energyMonth = formatEnergy(liveData[energyType].month);
  const energyYear = formatEnergy(liveData[energyType].year);
  const energyTotal = formatEnergy(liveData[energyType].total);

  return (
    <Widget
      title={title ?? formatMessage(energyType)}
      icon={icon}
      url={url}
      size={{ xs: 12 }}
      content={
        <Grid2 container spacing={2}>
          <Widget
            title={formatMessage("widget.today")}
            content={
              <WidgetContent value={energyDay.value} unit={energyDay.unit} />
            }
          />
          <Widget
            title={formatMessage("widget.thisMonth")}
            content={
              <WidgetContent
                value={energyMonth.value}
                unit={energyMonth.unit}
              />
            }
          />
          <Widget
            title={formatMessage("widget.thisYear")}
            content={
              <WidgetContent value={energyYear.value} unit={energyYear.unit} />
            }
          />
          <Widget
            title={formatMessage("widget.total")}
            content={
              <WidgetContent
                value={energyTotal.value}
                unit={energyTotal.unit}
              />
            }
          />
        </Grid2>
      }
    />
  );
};
