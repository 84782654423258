import { LoadingButton } from "@mui/lab";
import { TextField, Box, Typography, Grid2 } from "@mui/material";
import { useState } from "react";
import "./LoginPage.scss";

import { useLogin } from "api/queries/auth"; // Adjust this import path to match your project
import { handleUserResponse, setTokens, useAppDispatch } from "store";

// TODO - Rewrite this component, use react-hook-form
export const LoginPage = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  const loginMutation = useLogin();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null); // Reset error state
    try {
      await loginMutation.mutateAsync(
        { email, password },
        {
          onSuccess: (data) => {
            const { accessToken, refreshToken, ...rest } = data.data;
            dispatch(setTokens({ accessToken, refreshToken }));
            dispatch(handleUserResponse(rest));
          },
        },
      );
    } catch (error) {
      console.error("Login failed:", error);
      setError(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error as any)?.response?.data?.message ||
          "Login failed. Please try again.",
      );
    }
  };

  return (
    <>
      <Box className="flex flex-col items-center justify-center w-screen min-h-dvh">
        {/* <Icon className="sun">light_mode</Icon> */}
        <Grid2
          container
          direction={"column"}
          alignItems={"center"}
          rowGap={3}
          className="p-6 rounded-md shadow-md"
          sx={{ width: "80%", maxWidth: "400px" }}
        >
          <Typography
            variant="h4"
            component="div"
            color="primary"
            lineHeight={0.9}
            fontWeight={900}
          >
            Solar
          </Typography>
          <Typography variant="h6" fontSize={14}>
            Log into your account
          </Typography>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col space-y-4 w-full"
          >
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="email"
              className="mb-4"
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
              className="mb-4"
            />
            {error && (
              <Typography variant="body2" className="text-red-500">
                {error}
              </Typography>
            )}
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              disabled={loginMutation.isPending}
              loading={loginMutation.isPending}
            >
              Login
            </LoadingButton>
          </form>
        </Grid2>
      </Box>
    </>
  );
};
