import { Grid2, Grid2Props, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

export const WidgetContent = ({
  value,
  unit,
  gridProps,
}: {
  value: string | number;
  unit: string;
  gridProps?: Grid2Props;
}) => {
  return (
    <Grid2
      container
      direction={"column"}
      size={12}
      spacing={0}
      justifyContent="flex-end"
      {...gridProps}
    >
      <Typography
        align="right"
        variant="h4"
        fontSize={{ xs: "clamp(18px, 6vw, 26px)", sm: 26 }}
        fontWeight={700}
      >
        {value}
      </Typography>
      <Typography
        variant="h6"
        align="right"
        color={grey[700]}
        fontSize={12}
        fontWeight={700}
      >
        {unit}
      </Typography>
    </Grid2>
  );
};
