import { useQuery, useMutation, UseQueryOptions } from "@tanstack/react-query";

import { axiosInstance, USER, User, UserResponse } from "api";

export const useGetUser = (options: Partial<UseQueryOptions<UserResponse>>) => {
  return useQuery<UserResponse>({
    queryKey: ["getUser"],
    queryFn: async () => {
      const data = await axiosInstance.get<UserResponse>(USER.USER);
      return data.data;
    },
    ...options,
  });
};

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: ({ payload }: { userId: string; payload: Partial<User> }) =>
      axiosInstance.put<Partial<User>>(USER.USER, payload),
  });
};
