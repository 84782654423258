import { Divider, Grid2, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { Puller } from "./components";

export const PageTitle = ({ pageTitle }: { pageTitle: string }) => {
  return (
    <Grid2 container direction={"column"} spacing={3}>
      <Puller />
      <Typography
        variant="h4"
        component="div"
        color="primary"
        fontSize={28}
        lineHeight={0.9}
        fontWeight={900}
        paddingTop={2}
      >
        {pageTitle}
      </Typography>
      <Divider sx={{ my: 1, color: grey[100] }} />
    </Grid2>
  );
};
