import { RootState } from "./store";

export const getAppStore = (state: RootState) => state.app;
export const getLiveData = (state: RootState) =>
  state.app.selectedPowerPlant?.liveData;
export const getShowLiveData = (state: RootState) => state.app.showLiveData;
export const getPowerPlants = (state: RootState) => state.app.powerPlants;
export const getSelectedPowerPlant = (state: RootState) =>
  state.app.selectedPowerPlant;
export const getUser = (state: RootState) => state.app.user;
export const getTokens = (state: RootState) => state.app.tokens;
