import { useEffect } from "react";

import { Tokens, useGetPowerPlantLive, useGetUser } from "api";
import {
  getSelectedPowerPlant,
  getTokens,
  getUser,
  handleUserResponse,
  setLiveData,
  setTokens,
  useAppDispatch,
  useAppSelector,
} from "store";
import { TOKENS_LOCAL_STORAGE_KEY } from "store/consts";
import { getFromLocalStorage } from "utils/localStorage";

export const useSetupApp = () => {
  const dispatch = useAppDispatch();

  const tokens = useAppSelector(getTokens);
  const user = useAppSelector(getUser);
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);

  const { data: userData } = useGetUser({
    enabled: !!tokens?.accessToken && !user?.email,
  });

  const { data: liveData } = useGetPowerPlantLive({
    options: {
      refetchInterval: selectedPowerPlant?.liveDataInterval,
      refetchOnWindowFocus: true,
      staleTime: selectedPowerPlant?.liveDataInterval,
    },
  });

  // Load tokens from local storage only on first render
  useEffect(() => {
    const tokensLocalStorage = getFromLocalStorage<Tokens>(
      TOKENS_LOCAL_STORAGE_KEY,
    );
    if (tokensLocalStorage) {
      dispatch(setTokens(tokensLocalStorage));
    }
  }, [dispatch]);

  // Handle user response when user is not in store
  useEffect(() => {
    if (userData) {
      dispatch(handleUserResponse(userData));
    }
  }, [userData, dispatch]);

  // Handle live data response
  useEffect(() => {
    if (liveData) dispatch(setLiveData(liveData));
  }, [liveData, dispatch]);
};
