import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import {
  AUTH,
  axiosInstance,
  LoginRequest,
  LoginResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
} from "api";
import { logoutAction } from "utils/logout";

export const useLogin = () => {
  const mutation = useMutation<
    AxiosResponse<LoginResponse>,
    AxiosError,
    LoginRequest
  >({
    mutationFn: (data: LoginRequest) =>
      axiosInstance.post<LoginResponse>(AUTH.LOGIN, data),
  });
  return mutation;
};

export const useLogout = () => {
  return useMutation({
    mutationFn: () => axiosInstance.post<LoginResponse>(AUTH.LOGOUT),
    onSuccess: () => {
      logoutAction();
    },
  });
};

export const useRefreshTokens = () => {
  return useMutation({
    mutationFn: (data: RefreshTokenRequest) =>
      axiosInstance.post<RefreshTokenResponse>(AUTH.REFRESH_TOKEN, data),
  });
};
