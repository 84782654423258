import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Widget } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { getLiveData, useAppSelector } from "store";

export const AdditionalReadings = () => {
  const formatMessage = useFormatMessage();
  const liveData = useAppSelector(getLiveData);

  if (!liveData) return null;

  // TODO - some magic number here
  const readings: {
    name: "mv.voltage" | "mv.current" | "lv.current";
    R: number;
    S: number;
    T: number;
    unit: string;
  }[] = [
    {
      name: "mv.voltage",
      R: +(liveData.voltage.L1 * 0.3464).toFixed(2),
      S: +(liveData.voltage.L2 * 0.3464).toFixed(2),
      T: +(liveData.voltage.L3 * 0.3464).toFixed(2),
      unit: "kV",
    },
    {
      name: "mv.current",
      R: +(liveData.current.L1 * 10).toFixed(2),
      S: +(liveData.current.L2 * 10).toFixed(2),
      T: +(liveData.current.L3 * 10).toFixed(2),
      unit: "A",
    },
    {
      name: "lv.current",
      R: +(liveData.current.L1 * 500).toFixed(2),
      S: +(liveData.current.L2 * 500).toFixed(2),
      T: +(liveData.current.L3 * 500).toFixed(2),
      unit: "A",
    },
  ];

  return (
    <Widget
      title={formatMessage("additional.readings")}
      icon="note_add"
      size={{ xs: 12 }}
      content={
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">R</TableCell>
                <TableCell align="center">S</TableCell>
                <TableCell align="center">T</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {readings.map((reading) => (
                <TableRow
                  key={reading.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell key={reading.name} align="center">
                    {`${formatMessage(reading.name)} (${reading.unit})`}
                  </TableCell>
                  <TableCell align="center">{reading.R}</TableCell>
                  <TableCell align="center">{reading.S}</TableCell>
                  <TableCell align="center">{reading.T}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
};
