import { useIntl } from "react-intl";

import enMessages from "../messages/en-US.json";

export type MessageKey = keyof typeof enMessages;
export const messageKeys = Object.keys(enMessages);

export const useFormatMessage = () => {
  const { formatMessage: formatMessageIntl } = useIntl();

  // simplify formatMessage API
  const formatMessage = (
    messageKey: MessageKey,
    options: {
      values?: Record<string, string | number | Date>;
      defaultMessage?: string;
    } = {},
  ) => {
    const { values, defaultMessage } = options;

    try {
      return formatMessageIntl({ id: messageKey }, values);
    } catch {
      return defaultMessage || messageKey;
    }
  };

  return formatMessage;
};
