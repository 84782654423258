import { Box, Grid2 } from "@mui/material";

import { PageTitle } from "./components";

export const PageLayout = ({
  pageTitle,
  children,
}: React.PropsWithChildren<{ pageTitle: string }>) => {
  return (
    <Grid2
      container
      direction={"column"}
      p={3}
      spacing={3}
      height={"100%"}
      flexWrap={"nowrap"}
    >
      <PageTitle pageTitle={pageTitle} />
      <Box sx={{ overflowY: "auto", scrollbarGutter: "stable" }}>
        {children}
      </Box>
    </Grid2>
  );
};
