import { AppBar, Grid2, Toolbar, Typography } from "@mui/material";

import { getSelectedPowerPlant, useAppSelector } from "store";

import { NavbarDrawer } from "./components";
import { LiveData } from "./components/LiveData";

export const Navbar = () => {
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  return (
    <AppBar className="bg-black/70 backdrop-blur py-2 gap-2">
      <Toolbar className="flex justify-between">
        <Grid2 container direction="column">
          <Typography
            variant="h4"
            component="div"
            color="primary"
            lineHeight={0.9}
            fontWeight={900}
          >
            Solar
          </Typography>
          <Typography
            variant="h4"
            component="div"
            color="tertiary"
            lineHeight={0.9}
            fontWeight={700}
          >
            {selectedPowerPlant?.name || "Power Plant"}
          </Typography>
        </Grid2>
        <NavbarDrawer />
      </Toolbar>
      <LiveData />
    </AppBar>
  );
};
