import { QueryClient } from "@tanstack/react-query";
import axios from "axios";

import { useRefreshTokens } from "api";
import { getTokens, setTokens, useAppDispatch, useAppSelector } from "store";
import { logoutAction } from "utils/logout";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const axiosInstance = axios.create({
  baseURL: `${window.location.protocol}//${window.location.hostname}${import.meta.env.VITE_API_PORT ? `:${import.meta.env.VITE_API_PORT}` : ""}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const useSetupAxios = () => {
  const dispatch = useAppDispatch();
  const tokens = useAppSelector(getTokens);
  const refreshTokens = useRefreshTokens();

  axiosInstance.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization && tokens) {
        config.headers.Authorization = `Bearer ${tokens.accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response?.status === 401 &&
        !originalRequest._retry &&
        tokens?.refreshToken
      ) {
        originalRequest._retry = true;
        try {
          const { data } = await refreshTokens.mutateAsync({
            refreshToken: tokens.refreshToken,
          });

          dispatch(setTokens(data));
          originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          logoutAction();
          throw refreshError;
        }
      }
      return Promise.reject(error);
    },
  );
};
