import { QueryClientProvider } from "@tanstack/react-query";

import { queryClient } from "api";

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
