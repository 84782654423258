import { createContext, useContext } from "react";

/**
 * Create a context with a safe consumer hook.
 *
 * @param name - The name of the context, used in error messages.
 * @param defaultValue - Optional default value for the context.
 * @returns A tuple of [Context, useContextValue Hook].
 */
export const createSafeContext = <T>(
  name: string,
  defaultValue: T | null = null,
) => {
  const context = createContext<T | null>(defaultValue);

  const useContextValue = () => {
    const value = useContext(context);
    if (value === null) {
      throw new Error(
        `No ${name} provider in scope. Please wrap your components with a ${name} provider.`,
      );
    }
    return value;
  };

  return [context, useContextValue] as const;
};
