import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./styles/index.scss";

import { App } from "app";

const root = createRoot(document.getElementById("root") as HTMLElement);

if (import.meta.env.VITE_ENV === "development") {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
} else {
  root.render(<App />);
}
