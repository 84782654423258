import { PageLayout } from "components/layouts";
import { useFormatMessage } from "lang";

import { Records } from "./components";
import { RecordsContextProvider } from "./components/context";

export const RecordsPage = () => {
  const formatMessage = useFormatMessage();
  return (
    <RecordsContextProvider>
      <PageLayout pageTitle={formatMessage("pageTitle.records")}>
        <Records />
      </PageLayout>
    </RecordsContextProvider>
  );
};
