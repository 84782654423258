export const energyTypeList = [
  "energyConsumed",
  "energyProduced",
  "inductiveEnergyConsumed",
  "capacitiveEnergyProduced",
  "inductiveEnergyProduced",
  "capacitiveEnergyConsumed",
] as const;

export type EnergyType = (typeof energyTypeList)[number];

export const chartDate = ["day", "month", "year", "total"] as const;

export type ChartDateType = (typeof chartDate)[number];

export type GetRecordsRequest<SelectedMetrics extends EnergyType> = {
  startDate: string;
  endDate: string;
  timezone?: string;
  metrics?: SelectedMetrics[];
};

export type GetRecordsResponse<SelectedMetrics extends EnergyType> = (Record<
  SelectedMetrics,
  { calculated: number; raw: number }
> & {
  date: string;
})[];

export type PreparedRecord<SelectedMetrics extends EnergyType> = {
  [K in SelectedMetrics]: number | undefined;
} & {
  date: Date;
};

export type HelperFunctionsChart<SelectedMetrics extends EnergyType> = {
  [DateType in ChartDateType]: HelperFunctionsChartDateType<SelectedMetrics>;
};

export type HelperFunctionsChartDateType<SelectedMetrics extends EnergyType> = {
  start: (date: Date) => Date;
  end: (date: Date) => Date;
  prepareData: (
    data: GetRecordsResponse<SelectedMetrics>,
    date: Date,
  ) => PreparedRecord<SelectedMetrics>[];
  generateTicks: (date: Date) => number[];
  formatXAxis: (date: Date) => string;
  formatXTooltip: (date: Date) => string;
  formatYAxis: (value: number) => string;
  formatYTooltip: (value: number) => string;
  chartOptions: {
    scaleType: "time" | "band" | "auto";
    seriesType: "line" | "bar";
  };
};

export type HelperFunctionsDatePicker = {
  [DateType in ChartDateType]: {
    formatDateType?: (date: Date) => string;
    datePickerOptions: {
      views: ("year" | "month" | "day")[];
      hidden: boolean;
    };
  };
};
