import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Locale, setDefaultOptions } from "date-fns";
import { pl } from "date-fns/locale/pl";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";

import { LocaleContext } from "./LocaleContext";
import enMessages from "../messages/en-US.json";
import plMessages from "../messages/pl-PL.json";

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [locale, setLocale] = useState("pl");
  const [dateFnsLocale, setDateFnsLocale] = useState<Locale>(pl);

  const messages: Record<string, Record<string, string>> = {
    en: enMessages,
    pl: plMessages,
  };

  // Dynamically load the locale based on the selected language
  useEffect(() => {
    const loadLocale = async () => {
      if (locale === "pl") {
        const { pl } = await import("date-fns/locale/pl");
        setDateFnsLocale(pl);
        setDefaultOptions({ locale: pl });
      } else if (locale === "en") {
        const { enUS } = await import("date-fns/locale/en-US");
        setDateFnsLocale(enUS);
        setDefaultOptions({ locale: enUS });
      }
    };

    loadLocale();
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{ locale, setLocale, dateFnsLocale, setDateFnsLocale }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={dateFnsLocale}
      >
        <IntlProvider locale={locale} messages={messages[locale]}>
          {children}
        </IntlProvider>
      </LocalizationProvider>
    </LocaleContext.Provider>
  );
};
