import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ApiProvider } from "api";
import { LocaleProvider } from "lang";
import { Routes } from "routes";
import { store } from "store";
import theme from "styles/theme";

export const App: React.FC = () => {
  return (
    <ApiProvider>
      <LocaleProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </LocaleProvider>
    </ApiProvider>
  );
};
