import { tz } from "@date-fns/tz";
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns";

import {
  EnergyType,
  GetRecordsResponse,
  HelperFunctionsChart,
  PowerPlant,
  PreparedRecord,
} from "api";
import { formatEnergy, formatPower } from "utils/energy";

export const createHelperFunctionsGrouping = <
  SelectedMetrics extends EnergyType =
    | "energyConsumed"
    | "energyProduced"
    | "inductiveEnergyConsumed"
    | "capacitiveEnergyProduced"
    | "inductiveEnergyProduced"
    | "capacitiveEnergyConsumed",
>(
  powerPlant?: PowerPlant,
): HelperFunctionsChart<SelectedMetrics> => {
  const timezone = tz(powerPlant?.timezone || "Europe/Warsaw");
  const startDate = powerPlant?.startDate
    ? new Date(powerPlant.startDate)
    : new Date();

  const helpers: HelperFunctionsChart<SelectedMetrics> = {
    day: {
      start: (date: Date) =>
        startOfDay(date, {
          in: timezone,
        }),
      end: (date: Date) =>
        endOfDay(date, {
          in: timezone,
        }),
      formatXAxis: (date: Date) => {
        const dateString = format(date, "HH:mm");
        return dateString === "00:00" ? "" : dateString;
      },
      formatXTooltip: (date: Date) =>
        `${format(date, "HH:mm")} - ${format(addMinutes(date, 5), "HH:mm")}`,
      formatYAxis: (value: number) => formatPower(value).formatted,
      formatYTooltip: (value: number) => formatPower(value).formatted,
      chartOptions: {
        scaleType: "time",
        seriesType: "line",
      },
      prepareData: (data: GetRecordsResponse<SelectedMetrics>, date: Date) =>
        generateEmptyValuesIfMissing(
          prepareData(data),
          helpers.day.start(date),
          helpers.day.end(date),
          (date: Date) => addMinutes(date, 5),
          helpers.day.formatXAxis,
        ),
      generateTicks: (date) =>
        generateTicks(
          helpers.day.start(date),
          helpers.day.end(date),
          (date: Date) => addHours(date, 1),
        ),
    },
    month: {
      start: (date: Date) =>
        startOfMonth(date, {
          in: timezone,
        }),
      end: (date: Date) =>
        endOfMonth(date, {
          in: timezone,
        }),
      formatXAxis: (date: Date) => format(date, "d"),
      formatXTooltip: (date: Date) => format(date, "PPP"),
      formatYAxis: (value: number) => formatEnergy(value).formatted,
      formatYTooltip: (value: number) => formatEnergy(value).formatted,
      chartOptions: {
        scaleType: "auto",
        seriesType: "bar",
      },
      prepareData: (data: GetRecordsResponse<SelectedMetrics>, date: Date) =>
        generateEmptyValuesIfMissing(
          prepareData(data),
          helpers.month.start(date),
          helpers.month.end(date),
          (date: Date) => addDays(date, 1),
          helpers.month.formatXAxis,
        ),
      generateTicks: (date) =>
        generateTicks(
          helpers.month.start(date),
          helpers.month.end(date),
          (date: Date) => addDays(date, 1),
        ),
    },
    year: {
      start: (date: Date) =>
        startOfYear(date, {
          in: timezone,
        }),
      end: (date: Date) =>
        endOfYear(date, {
          in: timezone,
        }),
      formatXAxis: (date: Date) => format(date, "M"),
      formatXTooltip: (date: Date) => format(date, "LLLL Y"),
      formatYAxis: (value: number) => formatEnergy(value).formatted,
      formatYTooltip: (value: number) => formatEnergy(value).formatted,
      chartOptions: {
        scaleType: "auto",
        seriesType: "bar",
      },
      prepareData: (data: GetRecordsResponse<SelectedMetrics>, date: Date) =>
        generateEmptyValuesIfMissing(
          prepareData(data),
          helpers.year.start(date),
          helpers.year.end(date),
          (date: Date) => addMonths(date, 1),
          helpers.year.formatXAxis,
        ),
      generateTicks: (date) =>
        generateTicks(
          helpers.year.start(date),
          helpers.year.end(date),
          (date: Date) => addMonths(date, 1),
        ),
    },
    total: {
      start: () =>
        startOfYear(startDate, {
          in: timezone,
        }),
      end: () =>
        startOfYear(addYears(new Date(), 1), {
          in: timezone,
        }),
      formatXAxis: (date: Date) => format(date, "y"),
      formatXTooltip: (date: Date) => format(date, "Y"),
      formatYAxis: (value: number) => formatEnergy(value).formatted,
      formatYTooltip: (value: number) => formatEnergy(value).formatted,
      chartOptions: {
        scaleType: "auto",
        seriesType: "bar",
      },
      prepareData: (data: GetRecordsResponse<SelectedMetrics>) =>
        prepareData(data),
      generateTicks: (date) =>
        generateTicks(
          helpers.total.start(date),
          helpers.total.end(date),
          (date: Date) => addYears(date, 1),
        ),
    },
  };
  return helpers;
};

const prepareData = <
  SelectedMetrics extends EnergyType =
    | "energyConsumed"
    | "energyProduced"
    | "inductiveEnergyConsumed"
    | "capacitiveEnergyProduced"
    | "inductiveEnergyProduced"
    | "capacitiveEnergyConsumed",
>(
  data: GetRecordsResponse<SelectedMetrics>,
): PreparedRecord<SelectedMetrics>[] => {
  const sortedData = data.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  return sortedData.map((record) => {
    const updatedMetrics = Object.keys(record).reduce(
      (acc, field) => {
        if (field === "date") return acc;
        const currentMetric = record[field as SelectedMetrics];

        if (typeof currentMetric === "object")
          acc[field as SelectedMetrics] = currentMetric.calculated;
        else acc[field as SelectedMetrics] = 0;

        return acc;
      },
      {} as {
        [K in SelectedMetrics]: number;
      },
    );

    return {
      ...record,
      ...updatedMetrics,
      date: new Date(record.date),
    };
  }) as PreparedRecord<SelectedMetrics>[];
};

const generateEmptyValuesIfMissing = <
  SelectedMetrics extends EnergyType =
    | "energyConsumed"
    | "energyProduced"
    | "inductiveEnergyConsumed"
    | "capacitiveEnergyProduced"
    | "inductiveEnergyProduced"
    | "capacitiveEnergyConsumed",
>(
  data: PreparedRecord<SelectedMetrics>[],
  start: Date,
  end: Date,
  intervalFunction: (date: Date) => Date,
  formatXAxis: (date: Date) => string,
): PreparedRecord<SelectedMetrics>[] => {
  const metrics = Object.keys(data[0] || {}).filter(
    (key) => key !== "date",
  ) as SelectedMetrics[];

  const dataWithEmptyValues: PreparedRecord<SelectedMetrics>[] = [];
  let current = start;

  while (current <= end) {
    const existingRecord = data.find(
      (record) => formatXAxis(record.date) === formatXAxis(current),
    );

    if (existingRecord) {
      dataWithEmptyValues.push(existingRecord);
    } else {
      const emptyRecord = metrics.reduce(
        (acc, metric) => {
          acc[metric] = undefined;
          return acc;
        },
        {} as { [K in SelectedMetrics]: number | undefined },
      );

      dataWithEmptyValues.push({ ...emptyRecord, date: current });
    }

    current = intervalFunction(current);
  }

  return dataWithEmptyValues;
};

const generateTicks = (
  start: Date,
  end: Date,
  intervalFunction: (date: Date) => Date,
): number[] => {
  const ticks = [];
  let current = start;

  while (current <= end) {
    ticks.push(current.getTime());
    current = intervalFunction(current);
  }
  return ticks;
};
