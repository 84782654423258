import {
  Card,
  CardContent,
  Grid2,
  Typography,
  Icon,
  Collapse,
  Box,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Widget = forwardRef<
  HTMLDivElement,
  {
    size?: { xs?: number; sm?: number; md?: number };
    title?: string;
    content: JSX.Element;
    icon?: string;
    url?: string;
    onlyHeaderClickable?: boolean;
    collapsible?: boolean;
    expandedByDefault?: boolean;
  }
>(
  (
    {
      size = { xs: 6 },
      title,
      content,
      icon,
      url,
      onlyHeaderClickable,
      collapsible,
      expandedByDefault = true,
    },
    ref,
  ) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState<boolean>(expandedByDefault);

    return (
      <Grid2
        size={size}
        onClick={() => url && !onlyHeaderClickable && navigate(url)}
      >
        <Card variant="outlined">
          <CardContent
            ref={ref}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              rowGap: 0,
              height: "100%",
            }}
          >
            <Grid2
              container
              color={grey[700]}
              display={title || icon || url || collapsible ? "flex" : "none"}
              alignItems={"center"}
              justifyContent={"space-between"}
              onClick={() => {
                if (url && onlyHeaderClickable) navigate(url);
                if (collapsible) setExpanded(!expanded);
              }}
            >
              <Grid2 container spacing={1} alignItems={"center"}>
                <Icon
                  sx={{
                    fontSize: 18,
                    display: icon ? "block" : "none",
                  }}
                >
                  {icon}
                </Icon>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: 13,
                    lineHeight: "20px",
                    fontWeight: 500,
                    display: title ? "block" : "none",
                  }}
                >
                  {title}
                </Typography>
              </Grid2>
              <Icon
                sx={{
                  fontSize: collapsible ? 18 : 14,
                  display: url || collapsible ? "block" : "none",
                }}
              >
                {collapsible
                  ? expanded
                    ? "expand_less"
                    : "expand_more"
                  : "open_in_full"}
              </Icon>
            </Grid2>
            <Collapse in={expanded}>
              <Box paddingTop={1}>{content}</Box>
            </Collapse>
          </CardContent>
        </Card>
      </Grid2>
    );
  },
);
