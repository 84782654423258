import { Grid2, SwipeableDrawer } from "@mui/material";
import { Outlet, useNavigate, useOutlet } from "react-router";

import { DashboardPage } from "routes";
import { DEFAULT } from "routes/consts";

import { Navbar } from "./components";

export const AppLayout = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  return (
    <Grid2
      container
      direction={"column"}
      bgcolor={"background.default"}
      className="w-screen min-h-dvh h-full"
      sx={{
        scrollbarGutter: "stable",
        paddingTop: "78px",
        overflowX: "hidden",
      }}
    >
      <Navbar />
      <DashboardPage />

      <SwipeableDrawer
        anchor="bottom"
        open={!!outlet}
        onClose={() => navigate(DEFAULT)}
        onOpen={() => {}}
        disableDiscovery
        sx={{
          "& .MuiDrawer-paper": {
            height: "calc(100dvh - 108px)",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            bgcolor: "#1F1F1F",
            backgroundImage: "none",
          },
        }}
      >
        <Outlet />
      </SwipeableDrawer>
    </Grid2>
  );
};
