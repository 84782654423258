import { Grid2 } from "@mui/material";

import { EnergyChart, EnergyInfoSummary } from "components/elements/widgets";
import { useFormatMessage } from "lang";

import { useRecordsContext } from "./context";
import { RecordsOptionsPicker } from "./RecordsOptionsPicker";

export const Records = () => {
  const formatMessage = useFormatMessage();
  const { date, dateType, energyType } = useRecordsContext();

  return (
    <Grid2 container spacing={3}>
      <RecordsOptionsPicker />
      <EnergyChart date={date} dateType={dateType} energyType={energyType} />
      <EnergyInfoSummary
        title={formatMessage("statistics")}
        energyType={energyType}
        icon="analytics"
      />
    </Grid2>
  );
};
