import { Locale } from "date-fns";

import { createSafeContext } from "utils/createSafeContext";

type LocaleContextType = {
  locale: string;
  setLocale: React.Dispatch<React.SetStateAction<string>>;
  dateFnsLocale: Locale;
  setDateFnsLocale: React.Dispatch<React.SetStateAction<Locale>>;
};

// Use the simplified function to create context and hook
export const [LocaleContext, useLocaleContext] =
  createSafeContext<LocaleContextType>("LocaleContext");
