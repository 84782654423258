import { Action, combineReducers, configureStore } from "@reduxjs/toolkit";

import { setToLocalStorage } from "utils/localStorage";

import appReducer from "./app/appSlice";
import { TOKENS_LOCAL_STORAGE_KEY } from "./consts";

const mainReducer = combineReducers({
  app: appReducer,
});

const rootReducer = (
  state: ReturnType<typeof mainReducer> | undefined,
  action: Action,
) => {
  if (action.type === "USER_LOGOUT") {
    setToLocalStorage(TOKENS_LOCAL_STORAGE_KEY, undefined);
    return mainReducer(undefined, action);
  }

  return mainReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
